import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper.js'
import { VRButton } from 'three/examples/jsm/webxr/VRButton.js'
// import gsap from 'gsap'

// Scene
const scene = new THREE.Scene()

// Objects
const phonesGroup = new THREE.Object3D()
const instancedMeshes = []

// Misc. Global Vars
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
const count = 36

// Camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height)
camera.position.z = 3
camera.lookAt(phonesGroup)
scene.add(camera)

// Axes Helper
const axesHelper = new THREE.AxesHelper(1)

// Renderer
const renderer = new THREE.WebGL1Renderer({
    canvas: document.querySelector('canvas.webgl')
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(window.devicePixelRatio)
renderer.render(scene, camera)
const pmremGenerator = new THREE.PMREMGenerator( renderer )
pmremGenerator.compileEquirectangularShader()
const controls = new OrbitControls( camera, renderer.domElement )
// controls.autoRotate = true
controls.enableDamping = true
controls.dampingFactor = 0.1
document.body.appendChild( VRButton.createButton( renderer ) )
renderer.xr.enabled = true
renderer.setAnimationLoop(()=>{
    tick()
})

new RGBELoader()
    .setDataType( THREE.UnsignedByteType )
    .setPath( 'textures/equirectangular/' )
    .load( 'pedestrian_overpass_1k.hdr', function ( texture ) {
    // .load( 'quarry_01_1k.hdr', function ( texture ) {
        console.log('hi')

        // setup environment map
        const envMap = pmremGenerator.fromEquirectangular( texture ).texture
        // scene.background = envMap
        scene.environment = envMap
        texture.dispose()
        pmremGenerator.dispose()

        // use of RoughnessMipmapper is optional
        const roughnessMipmapper = new RoughnessMipmapper( renderer )
        const loader = new GLTFLoader().setPath( 'gltf/iphone/' )
        loader.load( 'iphone.gltf', function ( gltf ) {
            console.log('loaded')
            gltf.scene.traverse( function ( child ) {
                if ( child.isMesh ) {
                    roughnessMipmapper.generateMipmaps( child.material)
					var instancedMesh = new THREE.InstancedMesh( child.geometry, child.material, count )
                    instancedMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage)
                    var dummies = []

                    var r = 1
                    var x,y,z

                    for(var i=0; i<count; i++){
                        var dummy = new THREE.Object3D()
                        var d = (Math.PI*2)/count
                        var a = d*i
                        x = r * Math.cos(a)
                        y = r * Math.sin(a)
                        z = 0
                        dummy.position.set(x, y, z)
                        dummy.rotation.set(deg2rad(45),a,0)
                        dummy.start = {
                            r: r,
                            d: d,
                            a: a,
                            position: dummy.position.clone(),
                            rotation: dummy.rotation.clone()
                        }
                        dummy.updateMatrix()
                        dummies.push(dummy)
                        instancedMesh.setMatrixAt(i, dummy.matrix)
                        phonesGroup.add(instancedMesh)
                    }
                    instancedMesh.dummies = dummies
                    instancedMeshes.push(instancedMesh)
                    scene.add(phonesGroup)
				}
            })
            roughnessMipmapper.dispose()
            ready()
        })

    })

let ready = () => {

    tick()
}


// Animate
// gsap.to(mesh.position, {duration: 1, delay: 1, x:2})
const clock = new THREE.Clock()
var x = 0
const tick = () => {

    controls.update()

    const elapsedTime = clock.getElapsedTime()
    const speed = 1 * elapsedTime

    for (var n=0; n<instancedMeshes.length; n++){
        var im = instancedMeshes[n]
        for(var i=0; i<count; i++){
            var dummy = im.dummies[i]
            // dummy.rotation.x = deg2rad(i*20) + Math.cos(speed) * Math.PI
            dummy.rotation.z += 0.005
            dummy.position
            dummy.updateMatrix()
            im.setMatrixAt(i, dummy.matrix)
            im.instanceMatrix.needsUpdate = true
        }
    }

    // transform the whole group of phones
    phonesGroup.rotation.z += 0.003
    phonesGroup.rotation.x -= 0.0015
    phonesGroup.rotation.y += 0.005

    var s = 1 + Math.cos(speed*0.2) * 0.5
    phonesGroup.scale.set(s,s,s)

    // render the scene
    renderer.render(scene, camera)

    // window.requestAnimationFrame(tick) // replaced by renderer.setAnimationLoop
}

let deg2rad = (d) => {
    return d * (Math.PI/180)
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
})